/*
 * @Autor: xin
 * @Date: 2022-07-08 11:44:42
 * @LastEditors: xin
 * @LastEditTime: 2022-07-08 15:51:02
 * @FilePath: \train-pdf-project\src\router\index.js
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
