/*
 * @Autor: xin
 * @Date: 2022-07-08 15:44:32
 * @LastEditors: xin
 * @LastEditTime: 2022-07-09 14:31:54
 * @FilePath: \train-pdf-project\src\main.js
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import * as uni from '@/utils/uni.webview.1.5.2.js'  
// 绑定 uni 的 SDK
document.addEventListener("UniAppJSBridgeReady", () => {
  Vue.prototype.$uniAPI = uni 
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
